.sidebarminipanel {
  @apply fixed top-0 right-0 block w-full h-screen z-above-zendesk-widget bg-core-white;
  max-width: 500px;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.13);
  &.upsellSectionActive {
    right: -230px;
    @screen md {
      right: 0;
    }
  }
}


@media(max-width:1025px){
  .cdCartSideBar {
    @apply overflow-auto;
    background: rgba(0, 0, 0, 0.5);
  }
  .cdCartSideBar .cdAbsolute { @apply fixed;}
  .cdCartSideBar .sidebarminipanel {
    @apply absolute;
    height: inherit;
  }
}